@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

%smallWidgetsCommonLayout {
  & > div,
  & > iframe {
    width: 100%;

    @include h.breakpoint-m {
      @include h.grid-custom-column(6, 10);
      @include h.grid-custom-push(-1, 10);
      margin-right: h.$gutter-tablet;
    }

    @include h.breakpoint-l {
      @include h.grid-custom-column(5, 8);
      @include h.grid-custom-push(-1, 8);
      margin-right: h.$gutter-desktop;
    }

    @include h.breakpoint-x {
      @include h.grid-custom-column(4, 6);
      @include h.grid-custom-push(-1, 6);
    }
  }
}

.embed-widget__instatag-small {
  width: calc(100% + #{h.$outer-gutter * 2});
  margin-left: h.$neg-outer-gutter;

  @include h.breakpoint-m {
    width: 100%;
    margin-left: 0;
  }

  @extend %smallWidgetsCommonLayout;

  @media (min-width: 662px) {
    .instagram-media {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @include h.breakpoint-m {
    float: left;
    clear: both;
  }
}

iframe {
  // facebook's code adds an in-line min-width that we need to override
  min-width: 100% !important;
  max-width: 100%;
}

.embed-widget__youtube {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  clear: both;

  @include h.breakpoint-m {
    width: 100%;
    margin-left: 0;
  }

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.embed-widget__fb-post {
  position: relative;
  max-width: 100%;

  iframe {
    max-width: 100%;
    display: block;
    border: 0;
    margin: 0 auto;
  }

  .fb-post > span {
    max-width: 100%;
  }

  &.embed-widget__small-widget {
    @extend %smallWidgetsCommonLayout;
  }
}

.embed-widget__hoot-suite-wrapper {
  margin-bottom: h.rem(40px);

  iframe {
    width: 100%;
    height: h.rem(650px);
  }
}

.embed-widget__vimeo {
  div {
    position: relative;
    width: 100%;
    padding-top: calc(100% / (16 / 9)); // ratio of video
    box-sizing: border-box;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.embed-widget {
  margin-top: h.rem(48px);
  margin-bottom: h.rem(48px);

  .LiveBlogCard & {
    margin-top: h.rem(24px);
    margin-bottom: h.rem(24px);
  }
}

.embed-widget__menu {
  margin-top: h.rem(30px);
  margin-bottom: h.rem(30px);

  @include h.breakpoint-m {
    margin-top: h.rem(48px);
    margin-bottom: h.rem(48px);
  }
}

.embed-widget__twitter {
  @extend %smallWidgetsCommonLayout;
}

.embed-widget__fb-video {
  min-width: 100vw;
  max-width: h.rem(320px);
  margin-left: h.rem(h.$neg-outer-gutter);
  height: h.rem(160px);
  display: block;

  @include h.breakpoint-m {
    min-width: 100%;
    max-width: h.rem(609px);
    margin-left: 0;
    height: h.rem(490px);
  }

  @include h.breakpoint-l {
    height: h.rem(490px);
    max-width: h.rem(609px);
  }

  @include h.breakpoint-x {
    height: h.rem(350px);
    max-width: h.rem(545px);
  }
}

.embed-widget__fb-video-small {
  min-width: 100vw;
  max-width: h.rem(320px);
  margin-left: h.rem(h.$neg-outer-gutter);
  height: h.rem(160px);
  display: block;

  @include h.breakpoint-m {
    min-width: unset;
    max-width: 50%;
    display: block;
    margin-right: h.rem(40px);
    margin-left: 0;
  }
}

.embed-widget__instagram {
  clear: both;

  @include h.breakpoint-m {
    width: 100%;
    margin-left: 0;
  }

  @media (min-width: 662px) {
    .instagram-media {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  // Copied from embed markup
  .embed-widget__blockquote {
    background: var(--white);
    border: 0;
    border-radius: h.rem(3px);
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin: h.rem(1px);
    max-width: h.rem(658px);
    padding: 0;
    width: calc(100% - 2px);

    .embed-widget__outer {
      padding: h.rem(8px);

      .embed-widget__inner {
        background: #f8f8f8;
        line-height: 0;
        margin-top: h.rem(40px);
        padding: 37.5% 0;
        text-align: center;
        width: 100%;
      }

      .embed-widget__profile {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURczMzPf399fX1+bm5mzY9AMAAADiSURBVDjLvZXbEsMgCES5/P8/t9FuRVCRmU73JWlzosgSIIZURCjo/ad+EQJJB4Hv8BFt+IDpQoCx1wjOSBFhh2XssxEIYn3ulI/6MNReE07UIWJEv8UEOWDS88LY97kqyTliJKKtuYBbruAyVh5wOHiXmpi5we58Ek028czwyuQdLKPG1Bkb4NnM+VeAnfHqn1k4+GPT6uGQcvu2h2OVuIf/gWUFyy8OWEpdyZSa3aVCqpVoVvzZZ2VTnn2wU8qzVjDDetO90GSy9mVLqtgYSy231MxrY6I2gGqjrTY0L8fxCxfCBbhWrsYYAAAAAElFTkSuQmCC);
        display: block;
        height: h.rem(44px);
        margin: 0 auto h.rem(-44px);
        position: relative;
        top: h.rem(-22px);
        width: h.rem(44px);
      }

      .embed-widget__paragraph {
        margin: h.rem(8px) 0 0 0;
        padding: 0 h.rem(4px);
      }
    }
  }
}

.embed-widget__opinary {
  clear: both;
}

.embed-widget__opinary-widget-wrapper {
  border-top: 6px solid var(--embed-widget-border-top-color);
  border-left: 1px solid var(--embed-widget-border-color);
  border-right: 1px solid var(--embed-widget-border-color);
  border-bottom: 1px solid var(--embed-widget-border-color);
  margin-bottom: h.rem(40px);
}

.embed-widget__opinary-widget {
  position: relative;
  padding-top: 100%;
}

.embed-widget__pinterest {
  text-align: center;
}

.embed-widget__quiz-wrapper {
  margin-bottom: h.rem(40px);
}

.embed-widget--small .embed-widget__iframely-instagram {
  // Iframely enforces a min-width of 326px on Instagram embeds + 1px border on all sides
  $iframely-instagram-width: 326px + 2px;
  min-width: $iframely-instagram-width;
}

.embed-widget--edgetoedge {
  width: 100vw;
  margin-right: 0;
  margin-left: -50vw;
  left: 50%;

  &.embed-widget--shell-article {
    left: auto;
    margin-left: 0;
    margin-right: 0;
  }

  @include h.breakpoint-m {
    border-width: 0;
  }

  @include h.breakpoint-l {
    left: calc(50% + 180px);
  }

  @include h.breakpoint-x {
    left: calc(50% + 100px);
  }
}

.embed-widget--fullwidth,
.embed-widget--large,
.embed-widget--medium,
.embed-widget--small {
  @include h.breakpoint-m {
    left: auto;
  }
}

.embed-widget--fullwidth,
.embed-widget--large,
.embed-widget--medium {
  @include h.clearfix();
}

.embed-widget__use-presentation {
  @include h.breakpoint-m {
    float: left;
  }
}

.embed-widget--scroll-table {
  &:has(table) {
    overflow-x: auto;

    table {
      width: 100%;
    }

    // Keeps the scrollbar visible to indicate the content is scrollable
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
}

article.liveBlog {
  .iframely-firecracker {
    iframe {
      border: none;
    }
  }
}

.embed-widget--edgetoedge .embed-widget__use-presentation {
  float: none;
}

.embed-widget--fullwidth .embed-widget__use-presentation,
.embed-widget--large .embed-widget__use-presentation {
  @include h.breakpoint-m {
    @include h.grid-custom-column(12, 10);
    @include h.grid-custom-push(-1, 10);
  }

  @include h.breakpoint-l {
    width: 920px;
    margin-left: 0;
  }
}

.embed-widget--fullwidth .embed-widget__use-presentation {
  @include h.breakpoint-x {
    width: 1160px;
    margin-left: -100px;
  }
}

.embed-widget--large .embed-widget__use-presentation {
  @include h.breakpoint-x {
    width: 760px;
    margin-left: -100px;
  }
}

.embed-widget--medium .embed-widget__use-presentation {
  @include h.breakpoint-m {
    width: 100%;
  }

  @include h.breakpoint-m-only {
    .live-blog-card & {
      margin: 0;
    }
  }
}

.embed-widget--small .embed-widget__use-presentation {
  @include h.breakpoint-m {
    @include h.grid-custom-column(6, 10);
    margin: h.rem(10px) h.$gutter-tablet h.rem(10px) 0;
  }

  @include h.breakpoint-l {
    @include h.grid-custom-column(4.5, 7.5);
    margin-right: h.$gutter-desktop;
    margin-left: h.$neg-outer-gutter;
  }

  @include h.breakpoint-x {
    width: 360px;
    margin-left: -100px;
  }
}

.lazyload-wrapper {
  min-height: var(--iframe-height, auto);
}

.embed-widget--edgetoedge,
.embed-widget--fullwidth,
.embed-widget--large,
.embed-widget--medium,
.embed-widget--small {
  position: relative;

  &:has(.nbcSports) {
    margin-top: h.rem(24px);
    margin-bottom: h.rem(24px);
  }

  .nbcSports {
    aspect-ratio: 16 / 9;
    border: none;
    width: 100%;
  }
}
